// 租赁模块
import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
import {Lease} from './model/leaseModel';

export default {
  /**
   * @description: 租赁列表
   * @param {string} leaseId 租赁编号
   * @param {string} lessee 承租人
   * @param {string} city 城市
   * @param {string} leaderName 付款申请责任人
   * @param {string} purpose 租房类型
   * @param {string} fromDate 日期起
   * @param {string} toDate 日期止
   * @param {string} status 合同状态
   * @param {string} paymentPeriod 付款周期
   * @param {string} approvedStatus 审批状态
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/house_leases/list': (params: Lease, extra?: Extra) => {
    return axios(`/house_leases/list`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 创建房屋租赁合同
   * @param {string} leaseId 租赁编号
   * @param {string} lessee 承租人
   * @param {string} city 城市
   * @param {string} leaderName 付款申请责任人
   * @param {string} purpose 租房类型
   * @param {string} fromDate 日期起
   * @param {string} toDate 日期止
   * @param {string} status 合同状态
   * @param {string} paymentPeriod 付款周期
   * @param {string} approvedStatus 审批状态
   * @return {void}
   */
  'POST/house_leases': (params: Lease, extra?: Extra) => {
    return axios(`/house_leases`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 编辑房屋租赁合同
   * @param {number} id
   * @param {string} leaseId 租赁编号
   * @param {string} lessee 承租人
   * @param {string} city 城市
   * @param {string} leaderName 付款申请责任人
   * @param {string} purpose 租房类型
   * @param {string} fromDate 日期起
   * @param {string} toDate 日期止
   * @param {string} status 合同状态
   * @param {string} paymentPeriod 付款周期
   * @param {string} approvedStatus 审批状态
   * @return {void}
   */
  'PATCH/house_leases': (params: Lease, extra?: Extra) => {
    return axios(`/house_leases`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 编辑房屋租赁合同
   * @param {file} file
   * @return {void}
   */
  'POST/house_lease_contracts/upload': (params: any, extra?: Extra) => {
    return axios(`/house_lease_contracts/upload`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 删除房屋租赁合同
   * @param {number} id
   * @return {void}
   */
  'DELETE/house_leases/{id}': ({id}: {id: number}) => axios.delete<void>(`/house_leases/${id}`),

}
  
