/* 房屋租赁模块 */

import * as commonLib from '../modules/commonLib'
import {IResponseTypes, IModels} from './request'

export default function createFetch(fetchConfig: any) {
  const rapperFetch = commonLib.getRapperRequest(fetchConfig)

  return {
    /**
 * 接口名：查询房屋租赁合同信息(分页)
 * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2266334
 * @param req 请求参数
 * @param extra 请求配置项
 */
    /* 'POST/house_leases/list': (req?: IModels['POST/house_leases/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_leases/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_leases/list']>
    }, */

    /**
     * 接口名：创建房屋租赁合同
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2266304
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/house_leases': (req?: IModels['POST/house_leases']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_leases',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_leases']>
    }, */

    /**
     * 接口名：下载租赁合同、支付凭证文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2270601
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/house_lease_contracts/download': (
      req?: IModels['GET/house_lease_contracts/download']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_contracts/download',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/house_lease_contracts/download']>
    },

    /**
     * 接口名：修改房屋租赁合同信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2266327
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'PATCH/house_leases': (req?: IModels['PATCH/house_leases']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_leases',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/house_leases']>
    }, */

    /**
     * 接口名：删除房屋租赁合同信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2266331
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'DELETE/house_leases/{id}': (req?: IModels['DELETE/house_leases/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_leases/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/house_leases/{id}']>
    }, */

    /**
     * 接口名：根据id查询房屋租赁合同信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2266303
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/house_leases/{id}': (req?: IModels['GET/house_leases/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_leases/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/house_leases/{id}']>
    },

    /**
     * 接口名：上传租赁合同文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2270488
     * @param req 请求参数
     * @param extra 请求配置项
     */
    /* 'POST/house_lease_contracts/upload': (
      req?: IModels['POST/house_lease_contracts/upload']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_contracts/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_contracts/upload']>
    }, */

    /**
     * 接口名：根据租赁id获取合同文件列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2270602
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/house_lease_contracts': (req?: IModels['GET/house_lease_contracts']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_lease_contracts',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/house_lease_contracts']>
    },

    /**
     * 接口名：根据租赁id获取文件凭证
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2270947
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/lease_payment_logs': (req?: IModels['GET/lease_payment_logs']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/lease_payment_logs',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/lease_payment_logs']>
    },

    /**
     * 接口名：上传支付凭证文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2270943
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/lease_payment_logs/upload': (
      req?: IModels['POST/lease_payment_logs/upload']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/lease_payment_logs/upload',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/lease_payment_logs/upload']>
    },

    /**
     * 接口名：下载支付凭证文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2270945
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/lease_payment_logs/download': (
      req?: IModels['GET/lease_payment_logs/download']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/lease_payment_logs/download',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/lease_payment_logs/download']>
    },

    /**
     * 接口名：删除凭证记录文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2309176
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/lease_payment_logs/{id}': (
      req?: IModels['DELETE/lease_payment_logs/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/lease_payment_logs/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/lease_payment_logs/{id}']>
    },

    /**
     * 接口名：支付
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2279373
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_contracts/payment': (
      req?: IModels['POST/house_lease_contracts/payment']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_contracts/payment',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_contracts/payment']>
    },

    /**
     * 接口名：根据id获取所在办公地人员列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2287162
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/house_leases/user': (req?: IModels['GET/house_leases/user']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_leases/user',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/house_leases/user']>
    },

    /**
     * 接口名：获取最近填写的收款人信息
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2287603
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/house_leases/payee_info': (req?: IModels['GET/house_leases/payee_info']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_leases/payee_info',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/house_leases/payee_info']>
    },

    /**
     * 接口名：根据leaseId获取付款责任人明细
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2289894
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/house_lease_details/leader': (
      req?: IModels['GET/house_lease_details/leader']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_details/leader',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/house_lease_details/leader']>
    },

    /**
     * 接口名：新增责任人
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2290153
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_details': (req?: IModels['POST/house_lease_details']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_lease_details',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_details']>
    },

    /**
     * 接口名：编辑责任人
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2290223
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/house_lease_details': (req?: IModels['PATCH/house_lease_details']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_lease_details',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/house_lease_details']>
    },

    /**
     * 接口名：删除责任人
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2290245
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/house_lease_details/{id}': (
      req?: IModels['DELETE/house_lease_details/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_details/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/house_lease_details/{id}']>
    },

    /**
     * 接口名：根据leaseId获取租赁账单详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2293478
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_bill_details/list': (
      req?: IModels['POST/house_lease_bill_details/list']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_bill_details/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_bill_details/list']>
    },

    /**
     * 接口名：创建水电费
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2293480
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_bill_details/utilities': (
      req?: IModels['POST/house_lease_bill_details/utilities']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_bill_details/utilities',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_bill_details/utilities']>
    },

    /**
     * 接口名：选择多个账单汇总
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2293481
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_bill_details/info': (
      req?: IModels['POST/house_lease_bill_details/info']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_bill_details/info',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_bill_details/info']>
    },

    /**
     * 接口名：创建账单并移入支付池
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2293484
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_bill_details/bill': (
      req?: IModels['POST/house_lease_bill_details/bill']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_bill_details/bill',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_bill_details/bill']>
    },

    /**
     * 接口名：根据账单id获取此笔账单支付凭证
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2293496
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/lease_payment_logs/{id}': (req?: IModels['GET/lease_payment_logs/{id}']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/lease_payment_logs/{id}',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/lease_payment_logs/{id}']>
    },

    /**
     * 接口名：获取所有账单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2293549
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_bill_details/bill_info': (
      req?: IModels['POST/house_lease_bill_details/bill_info']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_bill_details/bill_info',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_bill_details/bill_info']>
    },

    /**
     * 接口名：修改账单详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2293889
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/house_lease_bill_details': (
      req?: IModels['PATCH/house_lease_bill_details']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_bill_details',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/house_lease_bill_details']>
    },

    /**
     * 接口名：删除合同记录同时删除文件
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2300237
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/house_lease_contracts/{id}': (
      req?: IModels['DELETE/house_lease_contracts/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_contracts/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/house_lease_contracts/{id}']>
    },

    /**
     * 接口名：审批房屋租赁
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2308848
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/house_leases/approve': (req?: IModels['GET/house_leases/approve']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_leases/approve',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/house_leases/approve']>
    },

    /**
     * 接口名：获取账单详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2310581
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/house_lease_bill_details': (
      req?: IModels['GET/house_lease_bill_details']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_bill_details',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/house_lease_bill_details']>
    },

    /**
     * 接口名：租赁导出
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2344533
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_leases/export': (req?: IModels['POST/house_leases/export']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_leases/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_leases/export']>
    },

    /**
     * 接口名：办公场地下拉框
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=511365&itf=2344876
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/house_leases/drop_list': (req?: IModels['GET/house_leases/drop_list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_leases/drop_list',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/house_leases/drop_list']>
    },

    /**
 * 接口名：年度成本导出
 * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516354&itf=2328393
 * @param req 请求参数
 * @param extra 请求配置项
 */
    'GET/house_lease_costs/year_export': (
      req?: IModels['GET/house_lease_costs/year_export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_costs/year_export',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/house_lease_costs/year_export']>
    },

    /**
     * 接口名：获取团队的成本总览
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516354&itf=2296288
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_costs/org': (req?: IModels['POST/house_lease_costs/org']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_lease_costs/org',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_costs/org']>
    },

    /**
     * 接口名：获取员工成本列表
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516354&itf=2295597
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_costs/list': (req?: IModels['POST/house_lease_costs/list']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_lease_costs/list',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_costs/list']>
    },

    /**
     * 接口名：根据团队id获取团队成本总览
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516354&itf=2297134
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_costs/every_org': (
      req?: IModels['POST/house_lease_costs/every_org']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_costs/every_org',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_costs/every_org']>
    },

    /**
     * 接口名：语言部译员效率值模块-译员列表
     */
    'GET/efficiency/translator/users': (
      req?: IModels['GET/efficiency/translator/users']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/efficiency/translator/users',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/efficiency/translator/users']>
    },
    
    /**
     * 接口名：语言部译员效率值模块-修改翻译字数
     */
    'POST/efficiency/translator/update': (
      req?: IModels['POST/efficiency/translator/update']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/efficiency/translator/update',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/efficiency/translator/update']>
    },
    /**
     * 接口名：语言部译员效率值模块-效率值查询
     */
    'POST/efficiency/translator': (
      req?: IModels['POST/efficiency/translator']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/efficiency/translator',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/efficiency/translator']>
    },
    /**
     * 接口名：语言部译员效率值模块-月度效率值管理查询
     */
    'POST/efficiency/translator/manage': (
      req?: IModels['POST/efficiency/translator/manage']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/efficiency/translator/manage',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/efficiency/translator/manage']>
    },
    /**
     * 接口名：语言部译员效率值模块-月度效率值管理导出
     */
    'POST/efficiency/translator/manage/export': (
      req?: IModels['POST/efficiency/translator/manage/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/efficiency/translator/manage/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/efficiency/translator/manage/export']>
    },
    /**
     * 接口名：语言部译员效率值模块-月度效率值管理导入模版下载
     */
    'POST/efficiency/translator/manage/import/template': (
      req?: IModels['POST/efficiency/translator/manage/import/template']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/efficiency/translator/manage/import/template',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/efficiency/translator/manage/import/template']>
    },
    /**
     * 接口名：语言部译员效率值模块-月度效率值管理导入
     */
    'POST/efficiency/translator/manage/import': (
      req?: IModels['POST/efficiency/translator/manage/import']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/efficiency/translator/manage/import',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/efficiency/translator/manage/import']>
    },
    /**
     * 接口名：语言部译员效率值模块-团队列表
     */
    'GET/efficiency/translator/orgList': (
      req?: IModels['GET/efficiency/translator/orgList']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/efficiency/translator/orgList',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/efficiency/translator/orgList']>
    },











    /**
     * 接口名：房屋物管租赁分摊
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516354&itf=2299181
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_costs/allocation': (
      req?: IModels['POST/house_lease_costs/allocation']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_costs/allocation',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_costs/allocation']>
    },

    /**
     * 接口名：租赁合同作废
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/house_lease_contracts/invalid': (
      req?: IModels['PATCH/house_lease_contracts/invalid']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_contracts/invalid',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/house_lease_contracts/invalid']>
    },

    /**
     * 接口名：团队年度查询
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516354&itf=2300651
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_costs/year_cost': (
      req?: IModels['POST/house_lease_costs/year_cost']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_costs/year_cost',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_costs/year_cost']>
    },

    /**
     * 接口名：根据年月和团队id查询分摊的员工
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516354&itf=2303979
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_costs/user': (req?: IModels['POST/house_lease_costs/user']['Req'], extra?: commonLib.IExtra) => {
      return rapperFetch({
        url: '/house_lease_costs/user',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_costs/user']>
    },

    /**
     * 接口名：根据员工id和年月获取成本
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516354&itf=2303980
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_costs/user_cost': (
      req?: IModels['POST/house_lease_costs/user_cost']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_costs/user_cost',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_costs/user_cost']>
    },

    /**
     * 接口名：删除个人成本
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516354&itf=2303981
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_costs/delete_cost': (
      req?: IModels['POST/house_lease_costs/delete_cost']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_costs/delete_cost',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_costs/delete_cost']>
    },

    /**
     * 接口名：将个人成本更换某个团队
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516354&itf=2304014
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'PATCH/house_lease_costs/update_org': (
      req?: IModels['PATCH/house_lease_costs/update_org']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_costs/update_org',
        method: 'PATCH',
        params: req,
        extra,
      }) as Promise<IResponseTypes['PATCH/house_lease_costs/update_org']>
    },

    /**
     * 接口名：导出员工租赁成本
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516354&itf=2307385
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_costs/export': (
      req?: IModels['POST/house_lease_costs/export']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_costs/export',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_costs/export']>
    },

    /**
     * 接口名：获取当前租赁的人员成本明细
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516354&itf=2308744
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_costs/lease_cost': (
      req?: IModels['POST/house_lease_costs/lease_cost']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_costs/lease_cost',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_costs/lease_cost']>
    },

    /**
     * 接口名：年度工位数使用情况详情
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=516354&itf=2327424
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'POST/house_lease_costs/year_seat': (
      req?: IModels['POST/house_lease_costs/year_seat']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_costs/year_seat',
        method: 'POST',
        params: req,
        extra,
      }) as Promise<IResponseTypes['POST/house_lease_costs/year_seat']>
    },

    /**
     * 接口名：租赁账单审批
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519132&itf=2312047
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'GET/house_lease_bills/approve': (
      req?: IModels['GET/house_lease_bills/approve']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_bills/approve',
        method: 'GET',
        params: req,
        extra,
      }) as Promise<IResponseTypes['GET/house_lease_bills/approve']>
    },

    /**
     * 接口名：删除租赁账单((水电费及其他)
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519132&itf=2312049
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/house_lease_bill_details/{id}': (
      req?: IModels['DELETE/house_lease_bill_details/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_bill_details/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/house_lease_bill_details/{id}']>
    },

    /**
     * 接口名：撤销账单
     * Rap 地址: http://rap2.taobao.org/repository/editor?id=301130&mod=519132&itf=2316348
     * @param req 请求参数
     * @param extra 请求配置项
     */
    'DELETE/house_lease_bills/{id}': (
      req?: IModels['DELETE/house_lease_bills/{id}']['Req'],
      extra?: commonLib.IExtra
    ) => {
      return rapperFetch({
        url: '/house_lease_bills/{id}',
        method: 'DELETE',
        params: req,
        extra,
      }) as Promise<IResponseTypes['DELETE/house_lease_bills/{id}']>
    },
  }
}